import { GAEvent } from '@/services/ga/events/GAEvent';

export class EventFilterUsers extends GAEvent {
  public eventName = 'settings-users-filter';
  public role?: string;

  constructor(role?: string) {
    super();
    this.role = role;
  }
}
