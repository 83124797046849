
import DraggablePanel from '@/components/buttons/DraggablePanel.vue';
import FormTextInput from '@/components/common/FormTextInput.vue';
import ComplianceSettingsModal from '@/components/modal/ComplianceSettingsModal.vue';
import { GetComplianceMethods } from '@/services/api/CustomSettingApi';
import { storeState } from '@/store/Globals';
import {
  ComplianceSettings,
  FormattedComplianceSettings
} from '@/store/models/MilkScore';
import CustomStore from '@/store/modules/CustomStore';
import User from '@/store/modules/User';
import { Constants } from '@/utils/Constants';
import { convertHrsToSecs, convertSecsToHrs } from '@/utils/FormattingUtils';
import { isAdmin } from '@/utils/TestingValidity';
import cloneDeep from 'lodash.clonedeep';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import DesktopHeader from '../common/DesktopHeader.vue';
import MobileHeader from '../common/MobileHeader.vue';
import ScrollableContainer from '../common/ScrollableContainer.vue';
import AppName from '../mixin/AppName.vue';
import ConfirmationModal from '../modal/ConfirmationModal.vue';
import ThresholdSettingsDisplay from './ThresholdSettingsDisplay.vue';
import { GA } from '@/services/ga/GoogleAnalytics';
import { EventSettingsRoleAdd } from '@/services/ga/events/EventSettingsRoleAdd';
import { EventSettingsAlertEdit } from '@/services/ga/events/EventSettingsAlertEdit';

@Component({
  components: {
    ScrollableContainer,
    DesktopHeader,
    DraggablePanel,
    ComplianceSettingsModal,
    FormTextInput,
    ConfirmationModal,
    MobileHeader,
    ThresholdSettingsDisplay
  },
  methods: {
    isAdmin
  },
  computed: {
    ...mapGetters('user', {
      token: 'token'
    })
  }
})
export default class AlertsSettingsTable extends mixins(AppName) {
  @Prop() isTable!: boolean;
  @Prop() width!: number;
  public selectedComplianceSettings: ComplianceSettings = {
    complianceMethod: 'MPI',
    complianceMethodThreshold: 1,
    maxMilkAgeSecs: 259200
  };
  public complianceMethods: FormattedComplianceSettings[] = [];
  public loading = false;

  get storeLoadingState() {
    return storeState.loading;
  }

  @Watch('storeLoadingState')
  storeStateUpdated() {
    if (!this.storeLoadingState) {
      this.loadComplianceMethods();
    }
  }

  mounted() {
    if (!this.storeLoadingState) {
      this.loadComplianceMethods();
    }
  }

  public loadComplianceMethods() {
    this.complianceMethods = cloneDeep(CustomStore.complianceMethods);
    this.complianceMethods.map(method => {
      method.ageThresholdHrs = convertSecsToHrs(+method.maxMilkAgeSecs);
      method.defaultChanged = false;
      if (method.default == undefined) {
        method.default = false;
      }
      return method;
    });
  }

  public saveThresholds(newComplianceSettings: FormattedComplianceSettings) {
    const originalComplianceMethods: ComplianceSettings[] = cloneDeep(
      CustomStore.complianceMethods
    );
    const alteredThresholds = originalComplianceMethods.map(method => {
      if (method.complianceMethod == newComplianceSettings.complianceMethod) {
        method.complianceMethodThreshold =
          newComplianceSettings.complianceMethodThreshold;
        if (newComplianceSettings.ageThresholdHrs) {
          method.maxMilkAgeSecs =
            convertHrsToSecs(newComplianceSettings.ageThresholdHrs) ?? 0;
        }
        if (newComplianceSettings.defaultChanged) {
          method.default = newComplianceSettings.default;
        }
      } else if (newComplianceSettings.defaultChanged) {
        method.default = false;
      }
      return method;
    });
    CustomStore.editCustomSettings({
      partnerId: User.orgId,
      entityType: 'vats',
      productType: Constants.PRODUCT_TYPE_MILK,
      data: { complianceMethods: alteredThresholds }
    }).then(() => {
      GA.event<EventSettingsAlertEdit>(
        this.$gtag,
        new EventSettingsAlertEdit('mpi-thresholds', {
          thresholds: newComplianceSettings
        })
      );

      this.loadComplianceMethods();
    });
  }

  public openModal(complianceSettings: ComplianceSettings) {
    this.selectedComplianceSettings = complianceSettings;
    this.$root.$emit(
      'bv::show::modal',
      'editDefaultComplianceModal',
      '#btnHide'
    );
  }
}
