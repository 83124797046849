
import FormTextInput from '@/components/common/FormTextInput.vue';
import DropdownOption from '@/store/models/DropdownOption';
import { FormattedComplianceSettings } from '@/store/models/MilkScore';
import { toTitleCase } from '@/utils/FormattingUtils';
import { formatMilkScoreColour } from '@/utils/TableFormatters';
import { isAdmin } from '@/utils/TestingValidity';
import { mixins } from 'vue-class-component';
import { Component, Emit, Model, Prop } from 'vue-property-decorator';
import FormDropdownInput from '../common/FormDropdownInput.vue';
import AppName from '../mixin/AppName.vue';

@Component({
  components: {
    FormTextInput,
    FormDropdownInput
  },
  methods: {
    isAdmin,
    toTitleCase,
    formatMilkScoreColour
  }
})
export default class ThresholdSettings extends mixins(AppName) {
  @Model() method!: FormattedComplianceSettings;

  @Emit('save')
  submitOnEnter() {
    return;
  }

  get defaultToggleText() {
    if (this.method.default) {
      return 'This is set as the default compliance method';
    } else {
      return 'Set this as the default compliance method';
    }
  }

  public defaultChanged() {
    if (!this.method.default) {
      this.method.default = true;
      this.method.defaultChanged = true;
    }
  }

  public updateComplianceMethodThreshold(value: DropdownOption) {
    this.method.complianceMethodThreshold = value.value as number;
  }
}
