
import DraggablePanel from '@/components/buttons/DraggablePanel.vue';
import FormDropdownInput from '@/components/common/FormDropdownInput.vue';
import FormTextInput from '@/components/common/FormTextInput.vue';
import CustomFieldsCardList from '@/components/list/CustomFieldsCardList.vue';
import AddCustomFieldDefModal from '@/components/modal/AddCustomFieldDefModal.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import EditCustomFieldDefModal from '@/components/modal/EditCustomFieldDefModal.vue';
import { trackEvent } from '@/services/Mixpanel';
import { storeState } from '@/store/Globals';
import CustomField from '@/store/models/CustomField';
import TableColumn from '@/store/models/TableColumn';
import CustomStore from '@/store/modules/CustomStore';
import User from '@/store/modules/User';
import {
  GetEntityCustomColumns,
  GetSiteCustomColumns
} from '@/utils/CustomColumns';
import { setObjectListItem } from '@/utils/LocalStorageUtils';
import { isAdmin } from '@/utils/TestingValidity';
import { Level } from '@/utils/types/Level';
import { mixins } from 'vue-class-component';
import { Component, Inject, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import DesktopHeader from '../common/DesktopHeader.vue';
import ScrollableContainer from '../common/ScrollableContainer.vue';
import AppName from '../mixin/AppName.vue';
import { PermissionService } from '@/services/PermissionService';
import { GA } from '@/services/ga/GoogleAnalytics';
import { EventSettingsAlertEdit } from '@/services/ga/events/EventSettingsAlertEdit';
import { EventSettingsCustomFieldEdit } from '@/services/ga/events/EventSettingsCustomFieldEdit';
import { EventSettingsCustomFieldAdd } from '@/services/ga/events/EventSettingsCustomFieldAdd';
import { EventSettingsEditGlobalEnable } from '@/services/ga/events/EventSettingsEditGlobalEnable';

@Component({
  components: {
    FormTextInput,
    FormDropdownInput,
    CustomFieldsCardList,
    AddCustomFieldDefModal,
    EditCustomFieldDefModal,
    ConfirmationModal,
    ScrollableContainer,
    DesktopHeader,
    DraggablePanel
  },
  methods: {
    isAdmin
  },
  computed: {
    ...mapGetters('user', {
      token: 'token'
    })
  }
})
export default class CustomFields extends mixins(AppName) {
  @Prop() isTable!: boolean;
  @Prop() width!: number;

  @Inject('permissionService')
  private permissionService!: PermissionService;

  public table: {
    products: { fields: any[]; items: TableColumn[] };
    entity: { fields: any[]; items: TableColumn[] };
  } = {
    products: {
      fields: [
        {
          key: 'label',
          label: 'Site'
        },
        'sortable',
        'searchable',
        'filterable',
        ' '
      ],
      items: []
    },
    entity: {
      fields: [
        {
          key: 'label',
          label: 'Entity'
        },
        'sortable',
        'searchable',
        'filterable',
        ' '
      ],
      items: []
    }
  };
  public selectedField = -1;
  public editFormField: CustomField = {
    name: '',
    type: '',
    label: '',
    sortable: false,
    filterable: false,
    listOptions: []
  };
  public addFormField = {
    name: '',
    type: '',
    label: '',
    sortable: false,
    filterable: false,
    listOptions: []
  };
  public deleteFormField = {
    name: '',
    type: '',
    label: '',
    sortable: false
  };
  public level: Level = 'products';

  public customFieldNames: string[] = [];
  public addFieldError = false;
  public editFieldError = false;
  public loading = true;
  public editGlobalOptions = false;

  get storeLoadingState() {
    return storeState.loading;
  }

  mounted() {
    this.getCustomFields();
  }

  created() {
    this.$root.$on('orgIndexChanged', () => {
      this.getCustomFields();
    });
  }

  get customFields(): CustomField[] {
    return JSON.parse(JSON.stringify(CustomStore.customFields ?? []));
  }

  get entityCustomFields(): CustomField[] {
    return JSON.parse(JSON.stringify(CustomStore.customEntityFields ?? []));
  }

  public levelCustomFields(level: 'products' | 'entity'): CustomField[] {
    return level == 'products' ? this.customFields : this.entityCustomFields;
  }

  public async getCustomFields() {
    setTimeout(async () => {
      GetSiteCustomColumns().then(data => {
        this.table.products.items = data;
        this.loading = false;
      });
      GetEntityCustomColumns().then(data => {
        this.table.entity.items = data;
        this.loading = false;
      });
    }, 500);
  }

  public toggle(
    field: 'sortable' | 'searchable' | 'filterable',
    index: number,
    level: Level
  ) {
    const toggleField = this.levelCustomFields(level).find(
      item => item.name == this.table[level].items[index].name
    );
    if (toggleField) {
      this.table[level].items[index][field] =
        !this.table[level].items[index][field] ?? false;
      toggleField[field] = this.table[level].items[index][field] ?? false;
    }

    if (toggleField) {
      CustomStore.editCustomFieldDefinitions({
        partnerId: User.orgId,
        entityType: level == 'entity' ? this.getEntityApiWord() : level,
        editType: 'update',
        data: toggleField
      }).then(() => {
        this.$root.$emit('customFieldsUpdated');
      });
    }
  }

  public get canManageCustomFields(): boolean {
    return this.permissionService.canManageCustomFields;
  }

  public changeSortable() {
    this.addFormField.sortable = !this.addFormField.sortable;
  }

  public editField(name: string, level: Level) {
    const item = this.levelCustomFields(level).find(item => item.name == name);
    if (item) {
      const index = this.levelCustomFields(level).indexOf(item);
      this.level = level;
      this.selectedField = index;
      this.editFormField = {
        name: item.name,
        label: item.label,
        type: item.type,
        sortable: item.sortable,
        filterable: item.filterable ?? false,
        listOptions: item.listOptions ?? []
      };
      this.$root.$emit('bv::show::modal', 'editSiteCustomField', '#btnShow');
    }
  }

  public deleteFieldModal(name: string, level: Level) {
    const item = this.levelCustomFields(level).find(item => item.name == name);
    if (item) {
      const index = this.levelCustomFields(level).indexOf(item);
      this.selectedField = index;
      this.level = level;

      this.$root.$emit('bv::show::modal', 'deleteCustomField', '#btnShow');
    }
  }

  public addFieldModal(level: Level) {
    this.addFormField = {
      name: '',
      label: '',
      type: '',
      sortable: false,
      filterable: false,
      listOptions: []
    };
    this.level = level;
    this.$root.$emit('bv::show::modal', 'addSiteCustomField', '#btnShow');
  }

  public deleteField() {
    this.closeDeleteModal();
    const deleted = this.levelCustomFields(this.level)[this.selectedField];
    CustomStore.editCustomFieldDefinitions({
      partnerId: User.orgId,
      entityType: this.level == 'entity' ? this.getEntityApiWord() : this.level,
      editType: 'delete',
      data: deleted
    }).then(data => {
      this.selectedField = 0;
      trackEvent('User deleted custom field');
      this.getCustomFields();
      this.$root.$emit('customFieldsUpdated');
    });
  }

  public onSubmit() {
    if (this.editFormField.name == '') {
      this.editFormField.name = this.levelCustomFields(this.level)[
        this.selectedField
      ].name;
    }
    if (this.editFormField.type == '') {
      this.editFormField.type = this.levelCustomFields(this.level)[
        this.selectedField
      ].type;
    }
    CustomStore.editCustomFieldDefinitions({
      partnerId: User.orgId,
      entityType: this.level == 'entity' ? this.getEntityApiWord() : this.level,
      editType: 'update',
      data: this.editFormField
    }).then(() => {
      const item = this.table[this.level].items.find(
        item => this.editFormField.name == item.name
      );
      if (item) {
        const index = this.table[this.level].items.indexOf(item);
        this.table[this.level].items[index].label = this.editFormField.label;
        this.table[this.level].items[
          index
        ].sortable = this.editFormField.sortable;
        this.table[this.level].items[index].type = this.editFormField.type;
      }
      trackEvent('User updated custom field', {
        customField: JSON.stringify(this.editFormField)
      });

      GA.event<EventSettingsCustomFieldEdit>(
        this.$gtag,
        new EventSettingsCustomFieldEdit(
          this.level == 'entity' ? this.getEntityApiWord() : this.level,
          this.editFormField.type,
          this.editFormField.sortable
        )
      );

      this.$root.$emit('customFieldsUpdated');
    });
    this.closeEditModal();
  }

  public submitAddNew() {
    if (this.addFormField.label == '' || this.addFormField.type == '') {
      this.addFieldError = true;
    } else {
      this.addFormField.name = this.addFormField.label;
      CustomStore.editCustomFieldDefinitions({
        partnerId: User.orgId,
        entityType:
          this.level == 'entity' ? this.getEntityApiWord() : this.level,
        editType: 'add',
        data: this.addFormField
      }).then(() => {
        setObjectListItem(
          `${this.getAppName(false)}FormFields`,
          this.levelCustomFields(this.level)
        );
        this.table[this.level].items.push({
          name: this.addFormField.name,
          label: this.addFormField.label,
          visible: false,
          sortable: this.addFormField.sortable,
          searchable: false,
          filterable: this.addFormField.filterable ?? false
        });

        trackEvent('User added custom field', {
          customField: JSON.stringify(this.addFormField)
        });

        GA.event<EventSettingsCustomFieldAdd>(
          this.$gtag,
          new EventSettingsCustomFieldAdd(
            this.level == 'entity' ? this.getEntityApiWord() : this.level,
            this.addFormField.type,
            this.addFormField.sortable
          )
        );

        setTimeout(() => {
          this.$root.$emit('customFieldsUpdated');
        }, 500);
        this.getCustomFields();
      });

      this.closeAddModal();
    }
  }

  public toggleEditGlobalOptions() {
    if (!this.editGlobalOptions) {
      this.$root.$emit('bv::show::modal', 'editGlobal', '#btnHide');
    } else {
      this.editGlobalOptions = !this.editGlobalOptions;
    }
  }

  public editGlobalConfirm() {
    this.editGlobalOptions = !this.editGlobalOptions;

    if (!this.editGlobalOptions) return;

    GA.event<EventSettingsEditGlobalEnable>(
      this.$gtag,
      new EventSettingsEditGlobalEnable()
    );
  }

  public closeEditModal() {
    this.$root.$emit('bv::hide::modal', 'editSiteCustomField', '#btnHide');
  }

  public closeDeleteModal() {
    this.$root.$emit('bv::hide::modal', 'deleteCustomField', '#btnHide');
  }

  public closeAddModal() {
    this.addFieldError = false;
    this.$root.$emit('bv::hide::modal', 'addSiteCustomField', '#btnHide');
  }
}
