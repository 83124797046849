var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BModal',{ref:"modal",staticClass:"d-flex",attrs:{"id":"editDefaultsModal","title":"Edit Defaults","centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"modal-title w-100 text-center",staticStyle:{"margin-right":"-44px"},attrs:{"id":"editDefaultsModal___BV_modal_title_"}},[_vm._v(" Edit Defaults ")]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" × ")])]},proxy:true}])},[_c('BAlert',{attrs:{"show":"","variant":"danger"}},[_c('BRow',[_c('BCol',{staticClass:"col40"},[_c('BIcon',{attrs:{"icon":"exclamation-triangle-fill"}})],1),_c('BCol',{staticClass:"p-0"},[_c('p',{staticClass:"m-0"},[_vm._v(" Editing these defaults will update the values for everyone in your company ")])])],1)],1),_c('div',[_c('h6',{staticClass:"mb-1"},[_vm._v("Defaults")]),_c('p',{staticClass:"text-mediumGrey"},[_vm._v(" These values will be used for general referencing ")]),_c('div',{staticClass:"mb-3"},[(_vm.editableOrgCustomSettings)?_c('BForm',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.saveDefaults.apply(null, arguments)}}},[(_vm.editableOrgCustomSettings.preferredLabel)?_c('FormDropdownInput',{attrs:{"inputAttrs":{
            id: 'score',
            label: 'Preferred Label',
            options: _vm.options(),
            variant: _vm.getAppColour(true),
            value: {
              value: _vm.editableOrgCustomSettings.preferredLabel,
              label: _vm.toTitleCase(_vm.editableOrgCustomSettings.preferredLabel)
            }
          },"id":"score"},on:{"input":_vm.updatePreferredLabel}}):_vm._e(),(_vm.editableOrgCustomSettings.preferredLevelMetric)?_c('FormDropdownInput',{attrs:{"inputAttrs":{
            id: 'score',
            label: 'Preferred Units',
            options: [
              { value: _vm.METRIC_VOLUME, label: 'Volume' },
              { value: _vm.METRIC_WEIGHT, label: 'Weight' }
            ],
            variant: _vm.getAppColour(true),
            value: {
              value: _vm.editableOrgCustomSettings.preferredLevelMetric,
              label: _vm.toTitleCase(
                _vm.editableOrgCustomSettings.preferredLevelMetric
              )
            }
          },"id":"score"},on:{"input":_vm.updatePreferredUnit}}):_vm._e()],1):_vm._e()],1)]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('BButton',{staticClass:"text-primary hover-white mt-4 mr-2 px-5",attrs:{"variant":"outline-primary"},on:{"click":_vm.closeModal}},[_vm._v("Cancel")]),_c('BButton',{staticClass:"text-white mt-4 ml-2 px-5",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.saveDefaults()}}},[_vm._v("Save")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }