import { GAEvent } from '@/services/ga/events/GAEvent';

export class EventSettingsEditDefault extends GAEvent {
  public eventName = 'settings-general-edit-defaults';
  public settings?: { [key: string]: any };

  constructor(settings?: { [key: string]: any }) {
    super();
    this.settings = settings;
  }
}
