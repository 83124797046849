var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BModal',{ref:"modal",staticClass:"d-flex",attrs:{"id":`add${_vm.modalType}CustomField`,"title":"Add custom field","centered":"","hide-footer":"","size":_vm.addFormField.type == 'List Entry' ? 'lg' : 'md'},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"modal-title w-100 text-center",staticStyle:{"margin-right":"-44px"},attrs:{"id":`add${_vm.modalType}CustomField___BV_modal_title_`}},[_vm._v(" Add "+_vm._s(_vm.modalType)+" Custom Field ")]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.closeAddModal()}}},[_vm._v(" × ")])]},proxy:true}])},[_c('BForm',{attrs:{"onsubmit":"return false"},on:{"submit":_vm.submitAddNew}},[_c('BRow',[_c('BCol',[_c('p',{staticClass:"inputBlockLabel pb-0"},[_vm._v("Add custom field")]),_c('FormTextInput',{attrs:{"inputAttrs":{
            id: 'label',
            label: 'Custom field name',
            labelClass: 'text-primary',
            type: 'text',
            variant: _vm.getAppColour(true)
          },"id":"label"},model:{value:(_vm.addFormField.label),callback:function ($$v) {_vm.$set(_vm.addFormField, "label", $$v)},expression:"addFormField.label"}}),_c('FormDropdownInput',{attrs:{"inputAttrs":{
            id: 'type',
            label: 'Type',
            options: ['Text entry', 'Numeric entry', 'List Entry'],
            variant: _vm.getAppColour(true),
            value: _vm.addFormField.type
          },"id":"type"},model:{value:(_vm.addFormField.type),callback:function ($$v) {_vm.$set(_vm.addFormField, "type", $$v)},expression:"addFormField.type"}}),_c('BInputGroup',{staticClass:"w-100"},[(_vm.addFormField.type == 'List Entry')?_c('FormTextInput',{attrs:{"inputAttrs":{
              id: 'listOptions',
              label: 'List item',
              labelClass: 'text-primary',
              type: 'text',
              variant: _vm.getAppColour(true),
              value: _vm.listOption
            },"id":"listOptions"},model:{value:(_vm.listOption),callback:function ($$v) {_vm.listOption=$$v},expression:"listOption"}}):_vm._e(),(_vm.addFormField.type == 'List Entry')?_c('BInputGroupAppend',[_c('BButton',{staticStyle:{"height":"50px"},attrs:{"variant":"primary"},on:{"click":_vm.addListItem}},[_vm._v("Add "),_c('BIcon',{attrs:{"icon":"plus"}})],1)],1):_vm._e()],1),_c('p',{staticClass:"pb-0 mb-0"},[_vm._v(" Allow this field to be sortable?"),_c('toggle-button',{staticClass:"pl-2",attrs:{"value":_vm.addFormField.sortable,"color":{
              checked: _vm.getAppColour(false),
              unchecked: '#ADADB6',
              disabled: '#CCCCCC'
            },"height":15,"width":25,"sync":true,"labels":false},on:{"change":function($event){return _vm.changeSortable()}}})],1),(_vm.addFormField.type == 'List Entry')?_c('p',{staticClass:"pb-0"},[_vm._v(" Allow this field to be filterable?"),_c('toggle-button',{staticClass:"pl-2",attrs:{"value":_vm.addFormField.filterable,"color":{
              checked: _vm.getAppColour(false),
              unchecked: '#ADADB6',
              disabled: '#CCCCCC'
            },"height":15,"width":25,"sync":true,"labels":false},on:{"change":function($event){return _vm.changeFilterable()}}})],1):_vm._e()],1),(_vm.addFormField.type == 'List Entry')?_c('BCol',[_c('p',{staticClass:"inputBlockLabel pb-0"},[_vm._v(" List selection items: ")]),(
            _vm.addFormField.listOptions && _vm.addFormField.listOptions.length > 0
          )?_c('BListGroup',_vm._l((_vm.addFormField.listOptions),function(item){return _c('BListGroupItem',{key:item,staticClass:"d-flex justify-content-between align-items-center"},[_vm._v(_vm._s(item)),_c('BIcon',{attrs:{"icon":"x-circle-fill"},on:{"click":function($event){return _vm.removeItem(item)}}})],1)}),1):_c('p',[_vm._v("No items added yet")])],1):_vm._e()],1),_c('BButton',{staticClass:"d-flex mx-auto text-white mt-4 px-5",attrs:{"type":"submit","variant":_vm.getAppColour(true)}},[_vm._v("Save")]),(_vm.addFieldError)?_c('p',{staticClass:"text-danger text-center pt-2"},[_vm._v(" Missing fields ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }