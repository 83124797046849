
import { Component, Vue, Prop } from 'vue-property-decorator';
import FormTextInput from '@/components/common/FormTextInput.vue';
import FormDropdownInput from '@/components/common/FormDropdownInput.vue';
import CustomField from '@/store/models/CustomField';
import { mapGetters } from 'vuex';
import CustomFieldsCardList from '@/components/list/CustomFieldsCardList.vue';
import { mixins } from 'vue-class-component';
import AppName from '@/components/mixin/AppName.vue';
import AddCustomFieldDefModal from '@/components/modal/AddCustomFieldDefModal.vue';

@Component({
  components: {
    FormTextInput,
    FormDropdownInput,
    CustomFieldsCardList,
    AddCustomFieldDefModal
  },
  computed: {
    ...mapGetters('user', {
      token: 'token'
    })
  }
})
export default class EditCustomFieldDefModal extends mixins(AppName) {
  @Prop() selectedField!: number;
  @Prop() editFormField!: CustomField;
  @Prop() formFields!: CustomField[];
  @Prop() modalType!: string;

  public customFieldNames: string[] = [];
  public addFieldError = false;
  public editFieldError = false;
  public orgId = 0;
  public listOption = '';
  public errorAlertSeconds = 3;
  public errorAlertCountdown = 0;

  get listOptions() {
    return this.editFormField.listOptions?.sort((a, b) => {
      if (a != null && b != null) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      } else if (a != null && b == null) {
        return -1;
      } else {
        return -1;
      }
    });
  }

  public addListItem() {
    if (this.editFormField.listOptions) {
      if (
        this.listOption &&
        !this.editFormField.listOptions.includes(this.listOption)
      ) {
        this.editFormField.listOptions.push(this.listOption);
        this.editFormField.listOptions.sort((a, b) => {
          if (a != null && b != null) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          } else if (a != null && b == null) {
            return -1;
          } else {
            return -1;
          }
        });
        this.listOption = '';
      } else if (this.editFormField.listOptions.includes(this.listOption)) {
        this.errorAlertCountdown = this.errorAlertSeconds;
      }
    } else {
      this.editFormField.listOptions = [this.listOption];
      this.listOption = '';
    }
  }

  public removeItem(item: string) {
    if (this.editFormField.listOptions) {
      this.editFormField.listOptions = this.editFormField.listOptions.filter(
        option => !(option == item)
      );
    }
  }

  public onSubmit() {
    if (
      (this.editFormField.type == 'List Entry' &&
        !this.editFormField.listOptions) ||
      (this.editFormField.type == 'List Entry' &&
        this.editFormField.listOptions &&
        this.editFormField.listOptions.length == 0)
    ) {
      this.editFieldError = true;
    } else {
      this.$emit('submitEdit');
    }
  }

  public closeEditModal() {
    this.$root.$emit(
      'bv::hide::modal',
      `edit${this.modalType}CustomField`,
      '#btnHide'
    );
  }
}
