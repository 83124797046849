var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BModal',{ref:"modal",staticClass:"d-flex",attrs:{"id":`edit${_vm.modalType}CustomField`,"title":`Edit ${
      _vm.formFields[_vm.selectedField] ? _vm.formFields[_vm.selectedField].label : ''
    } field`,"centered":"","hide-footer":"","size":_vm.editFormField.type == 'List Entry' ? 'lg' : 'md'},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"modal-title w-100 text-center",staticStyle:{"margin-right":"-44px"},attrs:{"id":`edit${_vm.modalType}CustomField___BV_modal_title_`}},[_vm._v(" Edit "+_vm._s(_vm.formFields[_vm.selectedField] ? _vm.formFields[_vm.selectedField].label : '')+" field ")]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.closeEditModal()}}},[_vm._v(" × ")])]},proxy:true}])},[_c('BForm',{attrs:{"onsubmit":"return false"},on:{"submit":_vm.onSubmit}},[_c('BRow',[_c('BCol',[_c('p',{staticClass:"inputBlockLabel pb-0"},[_vm._v("Edit field data")]),_c('FormTextInput',{attrs:{"inputAttrs":{
            id: 'label',
            label: 'Custom field name',
            labelClass: 'text-primary',
            type: 'text',
            value: _vm.formFields[_vm.selectedField]
              ? _vm.formFields[_vm.selectedField].label
              : '',
            variant: _vm.getAppColour(true)
          },"id":"label"},model:{value:(_vm.editFormField.label),callback:function ($$v) {_vm.$set(_vm.editFormField, "label", $$v)},expression:"editFormField.label"}}),_c('FormDropdownInput',{attrs:{"inputAttrs":{
            id: 'type',
            label: 'Type',
            value: _vm.formFields[_vm.selectedField]
              ? _vm.formFields[_vm.selectedField].type
              : '',
            options: ['Text entry', 'Numeric entry', 'List Entry'],
            variant: _vm.getAppColour(true),
            value: _vm.editFormField.type
          },"id":"type"},model:{value:(_vm.editFormField.type),callback:function ($$v) {_vm.$set(_vm.editFormField, "type", $$v)},expression:"editFormField.type"}}),_c('BInputGroup',{staticClass:"w-100"},[(_vm.editFormField.type == 'List Entry')?_c('FormTextInput',{attrs:{"inputAttrs":{
              id: 'listOptions',
              label: 'List item',
              labelClass: 'text-primary',
              type: 'text',
              variant: _vm.getAppColour(true),
              value: _vm.listOption
            },"id":"listOptions"},model:{value:(_vm.listOption),callback:function ($$v) {_vm.listOption=$$v},expression:"listOption"}}):_vm._e(),(_vm.editFormField.type == 'List Entry')?_c('BInputGroupAppend',[_c('BButton',{staticStyle:{"height":"50px"},attrs:{"variant":"primary"},on:{"click":_vm.addListItem}},[_vm._v("Add "),_c('BIcon',{attrs:{"icon":"plus"}})],1)],1):_vm._e()],1),_c('b-alert',{attrs:{"show":_vm.errorAlertCountdown,"dismissible":"","variant":"danger"},on:{"dismissed":function($event){_vm.errorAlertCountdown = 0}}},[_vm._v(" Already added as a list selection item ")])],1),(_vm.editFormField.type == 'List Entry')?_c('BCol',{attrs:{"sm":"12","md":"6"}},[_c('p',{staticClass:"inputBlockLabel pb-0"},[_vm._v(" List selection items: ")]),_c('div',{staticStyle:{"max-height":"350px","overflow-y":"auto"}},[(_vm.listOptions && _vm.listOptions.length > 0)?_c('BListGroup',_vm._l((_vm.listOptions),function(item){return _c('BListGroupItem',{key:item,staticClass:"d-flex justify-content-between align-items-center"},[_vm._v(_vm._s(item)),_c('BIcon',{attrs:{"icon":"x-circle-fill"},on:{"click":function($event){return _vm.removeItem(item)}}})],1)}),1):_c('p',[_vm._v("No items added yet")])],1)]):_vm._e()],1),_c('BButton',{staticClass:"d-flex mx-auto text-white mt-4 px-5",attrs:{"type":"submit","variant":_vm.getAppColour(true)}},[_vm._v("Save")]),(_vm.editFieldError)?_c('p',{staticClass:"text-danger text-center pt-2"},[_vm._v(" Empty fields ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }