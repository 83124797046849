
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component
export default class DisplayInfoItem extends mixins(AppName) {
  @Prop() label!: string;
  @Prop() value!: string;
}
