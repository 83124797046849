import { GAEvent } from '@/services/ga/events/GAEvent';

export class EventSearchUsers extends GAEvent {
  public eventName = 'settings-user-search';
  public searchTerm?: string;

  constructor(searchTerm?: string) {
    super();
    this.searchTerm = searchTerm;
  }
}
