
import Component, { mixins } from 'vue-class-component';
import AppName from '../mixin/AppName.vue';
import { Inject, Prop } from 'vue-property-decorator';
import {
  RoleManagementDetails,
  Permission
} from '@/store/models/RoleManagementDetails';
import groupBy from '@/utils/GroupBy';
import CurrentUser from '@/store/models/CurrentUser';
import ApiError from '@/services/api/models/ApiError';
import { PermissionService } from '@/services/PermissionService';
import { DeleteRole } from '@/services/RolesApi';
import { PartnerRole } from '@/services/api/models/PartnerRole';

@Component
export default class RoleManagementTable extends mixins(AppName) {

  @Prop() roleManagement!: RoleManagementDetails;
  @Prop() onRoleSelected!: (role: PartnerRole, permission: Permission) => Promise<void>;
  @Prop() onRoleDeleted!: () => Promise<void>;

  @Prop({ required: true, type: Array })
  public users!: (CurrentUser & { currentRole: PartnerRole | null })[];

  @Prop({ required: true, type: Number })
  private partnerId!: number;

  @Inject('permissionService')
  private permissionService!: PermissionService;

  public get permissionGroups() {
    return groupBy(
      this.roleManagement.permissions,
      (permission: Permission) => permission.groupName
    );
  }

  public get canEditSystemRoles(): boolean {
    return this.permissionService.canEditSystemRoles;
  }

  public nUsersWithRole(role: PartnerRole): number { 
    return this.users.filter(u => u.currentRole?.id === role.id).length;
  }

  public async confirmRoleDeletion(role: PartnerRole): Promise<void> {
    const result: boolean | null = await this.$bvModal
      .msgBoxConfirm(
        `Are you sure you want to delete the ${role.title} role?`,
        { centered: true }
      );

    if (!result) { return; }

    await this.deleteRole(role);
  }

  public roleHasPermission(
    role: PartnerRole,
    permission: Permission
  ): boolean {
    return role.permissions.includes(permission.id);
  }

  private async deleteRole(role: PartnerRole): Promise<void> {
    try {
      await DeleteRole({
        partnerId: this.partnerId,
        roleId: role.id
      });
      await this.onRoleDeleted();
      this.$bvToast.toast('Role deleted', { 
        toaster: 'b-toaster-bottom-center',
        solid: true,
        append: false
      });
    } catch (e) {
      const error = e as ApiError;
      this.$bvToast.toast(error.errorMessage, { 
        title: 'Error deleting role',
        toaster: 'b-toaster-bottom-center',
        solid: true,
        append: false
      });
    } 
  }

}
