
import { FormattedComplianceSettings } from '@/store/models/MilkScore';
import { toTitleCase } from '@/utils/FormattingUtils';
import {
  complianceMethodDescription,
  complianceMethodLabel
} from '@/utils/MilkScoreUtils';
import { formatMilkScoreColour } from '@/utils/TableFormatters';
import { isAdmin } from '@/utils/TestingValidity';
import { mixins } from 'vue-class-component';
import { Component, Emit, Model } from 'vue-property-decorator';
import DisplayInfoItem from '../common/DisplayInfoItem.vue';
import SidebarCard from '../common/SidebarCard.vue';
import AppName from '../mixin/AppName.vue';

@Component({
  components: {
    SidebarCard,
    DisplayInfoItem
  },
  methods: {
    isAdmin,
    toTitleCase,
    formatMilkScoreColour,
    complianceMethodLabel,
    complianceMethodDescription
  }
})
export default class ThresholdSettingsDisplay extends mixins(AppName) {
  @Model() method!: FormattedComplianceSettings;

  @Emit('edit')
  editMethod() {
    return this.method;
  }
}
