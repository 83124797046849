import { GAEvent } from '@/services/ga/events/GAEvent';

export class EventSettingsCustomFieldAdd extends GAEvent {
  public eventName = 'settings-custom-field-add';
  public entityType?: string;
  public customFieldType?: string;
  public sortable?: boolean;

  constructor(
    entityType?: string,
    customFieldType?: string,
    sortable?: boolean
  ) {
    super();
    this.entityType = entityType;
    this.customFieldType = customFieldType;
    this.sortable = sortable;
  }
}
