
import { Component, Vue, Prop, Model, Watch } from 'vue-property-decorator';
import FormTextInput from '@/components/common/FormTextInput.vue';
import User from '@/store/modules/User';

import { UpdateCustomSetting } from '@/services/api/CustomSettingApi';
import { Constants } from '@/utils/Constants';
import CustomStore from '@/store/modules/CustomStore';
import { FuelCustomSettingsData } from '@/store/models/CustomSettingsData';
import { cloneDeep } from 'lodash';
import { mixins } from 'vue-class-component';
import AppName from '../mixin/AppName.vue';

@Component({
  components: {
    FormTextInput
  }
})
export default class EditDefaultLevelsModal extends mixins(AppName) {
  @Prop() isLowLevel!: boolean;
  @Prop() value!: number;
  public editableValue = 0;

  mounted() {
    this.updateValue();
  }

  @Watch('value')
  updateValue() {
    this.editableValue = cloneDeep(this.value);
  }

  public increase() {
    this.editableValue = Math.round(this.editableValue / 5) * 5 + 5; //Round to nearest multiple of 5
  }

  public decrease() {
    this.editableValue = Math.round(this.editableValue / 5) * 5 - 5; //Round to nearest multiple of 5
  }

  public save() {
    if (this.isLowLevel) {
      CustomStore.editCustomSettings({
        partnerId: User.orgId,
        entityType: this.getEntityApiCamelCaseWord(),
        productType: this.getAppName(false),
        data: {
          ...(CustomStore.customEntitySettings as FuelCustomSettingsData),
          lowLevelThreshold: this.editableValue
        }
      }).then(() => {
        this.$emit('updated');
      });
    } else {
      CustomStore.editCustomSettings({
        partnerId: User.orgId,
        entityType: this.getEntityApiCamelCaseWord(),
        productType: this.getAppName(false),
        data: {
          ...(CustomStore.customEntitySettings as FuelCustomSettingsData),
          criticalLevelThreshold: this.editableValue
        }
      }).then(() => {
        this.$emit('updated');
      });
    }

    this.closeEditModal();
  }

  public closeEditModal() {
    this.$root.$emit(
      'bv::hide::modal',
      `editDefault${this.isLowLevel ? 'Low' : 'Critical'}LevelModal`,
      '#btnHide'
    );
  }
}
