
import AppName from '@/components/mixin/AppName.vue';
import TableColumn from '@/store/models/TableColumn';
import { mixins } from 'vue-class-component';
import { Component, Emit, Model, Prop } from 'vue-property-decorator';
import ListCardItem from '../cards/ListCardItem.vue';
import MobileContainer from '../common/MobileContainer.vue';
import MobileHeader from '../common/MobileHeader.vue';
import ScrollableContainer from '../common/ScrollableContainer.vue';

import { toTitleCase } from '@/utils/FormattingUtils';
import { Level } from '@/utils/types/Level';
import { isAdmin } from '@/utils/TestingValidity';

@Component({
  components: {
    ScrollableContainer,
    MobileContainer,
    MobileHeader,
    ListCardItem
  },
  methods: { toTitleCase, isAdmin }
})
export default class CustomFieldsCardList extends mixins(AppName) {
  @Prop() customFields!: {
    products: { fields: any[]; items: TableColumn[] };
    entity: { fields: any[]; items: TableColumn[] };
  };
  @Prop() editGlobalOptions!: boolean;

  @Prop({ required: true, type: Boolean })
  public canEdit!: boolean;

  @Emit('updateEditGlobalOptions')
  updateEditGlobalOptions() {
    return;
  }

  @Emit('addCustomField')
  public addCustomField(level: Level) {
    return level;
  }

  public editCustomField(name: string, level: Level) {
    this.$emit('editCustomField', name, level);
  }

  public deleteCustomField(name: string, level: Level) {
    this.$emit('deleteCustomField', name, level);
  }

  public toggle(
    field: 'sortable' | 'searchable' | 'filterable',
    index: number,
    level: Level
  ) {
    this.$emit('toggle', field, index, level);
  }
}
