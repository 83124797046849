import { GAEvent } from '@/services/ga/events/GAEvent';

export class EventSettingsAlertEdit extends GAEvent {
  public eventName = 'settings-alert-edit';
  public alertType?: string;
  public parameters?: { [key: string]: any };

  constructor(alertType?: string, parameters?: { [key: string]: any }) {
    super();
    this.alertType = alertType;
    this.parameters = parameters;
  }
}
