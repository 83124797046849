
import FormTextInput from '@/components/common/FormTextInput.vue';
import { trackEvent } from '@/services/Mixpanel';
import {
  FeedCustomSettingsData,
  FuelCustomSettingsData,
  MilkCustomSettingsData
} from '@/store/models/CustomSettingsData';
import DropdownOption from '@/store/models/DropdownOption';
import { FormattedComplianceSettings } from '@/store/models/MilkScore';
import CustomStore from '@/store/modules/CustomStore';
import User from '@/store/modules/User';
import { Constants } from '@/utils/Constants';
import { toTitleCase } from '@/utils/FormattingUtils';
import { AmountMetric } from '@/utils/types/AmountMetric';
import cloneDeep from 'lodash.clonedeep';
import { mixins } from 'vue-class-component';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import FormDropdownInput from '../common/FormDropdownInput.vue';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import ThresholdSettings from '../settings/ThresholdSettings.vue';
import { GA } from '@/services/ga/GoogleAnalytics';
import { EventSettingsEditGlobalEnable } from '@/services/ga/events/EventSettingsEditGlobalEnable';
import { EventSettingsEditDefault } from '@/services/ga/events/EventSettingsEditDefault';

@Component({
  components: {
    FormTextInput,
    FormDropdownInput,
    ThresholdSettings
  },
  methods: {
    toTitleCase
  }
})
export default class EditPreferredDefaultsModal extends mixins(
  AppName,
  ConstantsMixin
) {
  @Prop() orgCustomSettings!:
    | FuelCustomSettingsData
    | FeedCustomSettingsData
    | MilkCustomSettingsData
    | null;
  public editableOrgCustomSettings:
    | FuelCustomSettingsData
    | FeedCustomSettingsData
    | MilkCustomSettingsData
    | null = null;

  mounted() {
    this.updateEditableSettings();
  }

  @Watch('orgCustomSettings')
  updateEditableSettings() {
    if (this.orgCustomSettings) {
      this.editableOrgCustomSettings = cloneDeep(this.orgCustomSettings);
    }
  }

  @Emit('saveDefaults')
  public async saveDefaults() {
    await this.save();
    this.closeModal();
    return this.editableOrgCustomSettings;
  }

  public options() {
    if (this.isApp(Constants.PRODUCT_TYPE_FEED)) {
      return [
        {
          value: this.ENTITY_LABEL_SILO,
          label: toTitleCase(this.ENTITY_LABEL_SILO)
        },
        {
          value: this.ENTITY_LABEL_TANK,
          label: toTitleCase(this.ENTITY_LABEL_TANK)
        },
        {
          value: this.ENTITY_LABEL_BIN,
          label: toTitleCase(this.ENTITY_LABEL_BIN)
        },
        {
          value: this.ENTITY_LABEL_VAT,
          label: toTitleCase(this.ENTITY_LABEL_VAT)
        }
      ];
    } else if (this.isApp(Constants.PRODUCT_TYPE_MILK)) {
      return [
        {
          value: this.ENTITY_LABEL_VAT,
          label: toTitleCase(this.ENTITY_LABEL_VAT)
        },
        {
          value: this.ENTITY_LABEL_TANK,
          label: toTitleCase(this.ENTITY_LABEL_TANK)
        }
      ];
    }
  }

  public updatePreferredLabel(value: DropdownOption) {
    if (this.editableOrgCustomSettings) {
      this.editableOrgCustomSettings.preferredLabel = value.value as string;
    }
  }

  public updatePreferredUnit(value: DropdownOption) {
    if (this.editableOrgCustomSettings) {
      this.editableOrgCustomSettings.preferredLevelMetric = value.value as AmountMetric;
    }
  }

  public async save() {
    let settingsToSave = {
      ...CustomStore.customEntitySettings,
      preferredLabel: this.editableOrgCustomSettings?.preferredLabel
    };
    if (this.editableOrgCustomSettings?.preferredLevelMetric) {
      settingsToSave = {
        ...CustomStore.customEntitySettings,
        preferredLabel: this.editableOrgCustomSettings?.preferredLabel,
        preferredLevelMetric: this.editableOrgCustomSettings
          ?.preferredLevelMetric
      };
    }
    await CustomStore.editCustomSettings({
      partnerId: User.orgId,
      entityType: this.getEntityApiCamelCaseWord(),
      productType: this.getAppName(false),
      data: settingsToSave
    });

    trackEvent(`User changed preferred ${this.getAppName(false)} defaults`, {
      preferredLabel: this.editableOrgCustomSettings?.preferredLabel ?? '',
      preferredLevelMetric:
        this.editableOrgCustomSettings?.preferredLevelMetric ?? ''
    });

    GA.event<EventSettingsEditDefault>(
      this.$gtag,
      new EventSettingsEditDefault(settingsToSave)
    );
  }

  public closeModal() {
    this.$root.$emit('bv::hide::modal', `editDefaultsModal`, '#btnHide');
  }
}
