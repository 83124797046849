
import FormTextInput from '@/components/common/FormTextInput.vue';
import { FormattedComplianceSettings } from '@/store/models/MilkScore';
import { complianceMethodLabel } from '@/utils/MilkScoreUtils';
import cloneDeep from 'lodash.clonedeep';
import { mixins } from 'vue-class-component';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ThresholdSettings from '../settings/ThresholdSettings.vue';

@Component({
  components: {
    FormTextInput,
    ThresholdSettings
  },
  methods: {
    complianceMethodLabel
  }
})
export default class ComplianceSettingsModal extends mixins(AppName) {
  @Prop() complianceSettings!: FormattedComplianceSettings;
  public editableComplianceSettings: FormattedComplianceSettings | null = null;

  mounted() {
    this.updateEditableSettings();
  }

  @Watch('complianceSettings')
  updateEditableSettings() {
    if (this.complianceSettings) {
      this.editableComplianceSettings = cloneDeep(this.complianceSettings);
    }
  }

  @Emit('saveThresholds')
  public saveThresholds() {
    this.closeModal();
    return this.editableComplianceSettings;
  }

  public closeModal() {
    this.$root.$emit(
      'bv::hide::modal',
      `editDefaultComplianceModal`,
      '#btnHide'
    );
  }
}
