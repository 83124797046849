var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BContainer',{staticClass:"border h-100 bg-white p-0",class:_vm.width > 1000 ? 'contentMain' : '',attrs:{"fluid":""}},[(_vm.width > 1000)?_c('div',{staticClass:"bg-lightGrey border-bottom pt-4 px-4 text-left",attrs:{"id":"settingsHeader"}},[_c('h3',{staticClass:"pt-3"},[_vm._v("Settings")]),_c('p',{staticClass:"pt-3 my-2"},[(_vm.canManageUsers)?_c('span',{staticClass:"tab",class:_vm.tab == 'user-management'
            ? 'bg-white border-top border-left border-right'
            : 'bg-lightGrey',on:{"click":function($event){return _vm.switchTabs('user-management')}}},[_vm._v("Users")]):_vm._e(),(_vm.canManageRoles)?_c('span',{staticClass:"tab",class:_vm.tab == 'role-management'
            ? 'bg-white border-top border-left border-right'
            : 'bg-lightGrey',on:{"click":function($event){return _vm.switchTabs('role-management')}}},[_vm._v("Manage Roles")]):_vm._e(),(!_vm.isApp(_vm.PRODUCT_TYPE_WATER) && _vm.canManageDefaultAlerts)?_c('span',{staticClass:"tab",class:_vm.tab == 'alerts-settings'
            ? 'bg-white  border-top border-left border-right'
            : 'bg-lightGrey',on:{"click":function($event){return _vm.switchTabs('alerts-settings')}}},[_vm._v("Alerts")]):_vm._e(),(_vm.canManageCustomFields)?_c('span',{staticClass:"tab",class:_vm.tab == 'custom-field'
            ? 'bg-white  border-top border-left border-right'
            : 'bg-lightGrey',on:{"click":function($event){return _vm.switchTabs('custom-field')}}},[_vm._v("Custom Fields")]):_vm._e(),(!_vm.isApp(_vm.PRODUCT_TYPE_FUEL) && !_vm.isApp(_vm.PRODUCT_TYPE_WATER) && _vm.canEditGeneralSettings)?_c('span',{staticClass:"tab",class:_vm.tab == 'defaults'
            ? 'bg-white  border-top border-left border-right'
            : 'bg-lightGrey',on:{"click":function($event){return _vm.switchTabs('defaults')}}},[_vm._v("General Settings")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"bg-white"},[_c('router-view')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }