var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.method)?_c('div',[_c('h6',{staticClass:"mb-1"},[_vm._v("Thresholds")]),_c('p',{staticClass:"text-mediumGrey"},[_vm._v(" These are the thresholds that will trigger an alert email to be sent ")]),_c('div',{staticClass:"mb-3"},[_c('BForm',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submitOnEnter.apply(null, arguments)}}},[_c('div',[_c('FormDropdownInput',{attrs:{"inputAttrs":{
            id: 'score',
            label: 'Score',
            options: [
              { value: 1, label: 'Yellow' },
              { value: 2, label: 'Orange' },
              { value: 3, label: 'Red' }
            ],
            variant: _vm.getAppColour(true),
            value: {
              value: _vm.method.complianceMethodThreshold,
              label: _vm.toTitleCase(
                _vm.formatMilkScoreColour(_vm.method.complianceMethodThreshold, false)
              )
            }
          },"id":"score"},on:{"input":_vm.updateComplianceMethodThreshold}}),_c('FormTextInput',{attrs:{"inputAttrs":{
            id: 'age',
            label: 'Age (hours)',
            labelClass: 'text-primary',
            type: 'text',
            variant: _vm.getAppColour(true),
            value: _vm.method.ageThresholdHrs
          },"id":"age"},model:{value:(_vm.method.ageThresholdHrs),callback:function ($$v) {_vm.$set(_vm.method, "ageThresholdHrs", $$v)},expression:"method.ageThresholdHrs"}})],1),_c('div',[_c('h6',{staticClass:"mb-1"},[_vm._v("Default Compliance Method")]),_c('p',{staticClass:"text-mediumGrey mb-2"},[_vm._v(" "+_vm._s(_vm.defaultToggleText)+" ")]),_c('toggle-button',{staticClass:"my-auto mr-2",class:!_vm.method.default ? 'pointerOnHover' : 'defaultOnHover',attrs:{"value":_vm.method.default,"color":{
            checked: _vm.getAppColour(false),
            unchecked: '#ADADB6'
          },"height":25,"width":45,"sync":true,"labels":false},on:{"change":_vm.defaultChanged}})],1)])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }