
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component
export default class ListCardItem extends mixins(AppName, ConstantsMixin) {
  @Prop() title!: string;
  @Prop() subTitle!: string;
}
