
import { Component, Inject, Prop } from 'vue-property-decorator';
import {
  RoleManagementDetails,
  Permission
} from '@/store/models/RoleManagementDetails';

import DraggablePanel from '@/components/buttons/DraggablePanel.vue';
import RoleManagementTable from '@/components/settings/RoleManagementTable.vue';
import SettingsHeader from '@/components/settings/SettingsHeader.vue';
import AddRoleForm from '@/components/settings/AddRoleForm.vue';

import User from '@/store/modules/User';
import { mixins } from 'vue-class-component';
import AppName from '../mixin/AppName.vue';
import ProductStore from '@/store/modules/ProductStore';
import CurrentUser from '@/store/models/CurrentUser';
import ApiError from '@/services/api/models/ApiError';
import { PermissionService } from '@/services/PermissionService';
import {
  CreateRole,
  GetRoleManagementDetails,
  ToggleRolePermission
} from '@/services/RolesApi';
import { PartnerRole } from '@/services/api/models/PartnerRole';
import { GA } from '@/services/ga/GoogleAnalytics';
import { EventSettingsUserAdd } from '@/services/ga/events/EventSettingsUserAdd';
import { EventSettingsRoleAdd } from '@/services/ga/events/EventSettingsRoleAdd';

@Component({
  components: {
    DraggablePanel,
    RoleManagementTable,
    SettingsHeader,
    AddRoleForm
  }
})
export default class RoleManagement extends mixins(AppName) {
  @Prop() isTable!: boolean;
  @Prop() width!: number;

  @Inject('permissionService')
  private permissionService!: PermissionService;

  public loading = true;
  public errorMessage: string | null = null;
  public roleManagement: RoleManagementDetails | null = null;
  public users: CurrentUser[] = [];

  public get canAddRoles(): boolean {
    return this.permissionService.canAddRoles;
  }

  public get settingsButtonProps(): {
    label: string;
    onClick: () => void;
    disabled: boolean;
  } {
    return {
      label: '+ Add Role',
      onClick: () => this.openAddRoleModal(),
      disabled: !this.canAddRoles
    };
  }

  public mounted() {
    this.loadRoleManagementDetails();
    this.fetchUsers();
  }

  public async onRolePermissionToggled(
    role: PartnerRole,
    permission: Permission
  ): Promise<void> {
    try {
      await ToggleRolePermission({
        partnerId: this.currentOrgId(),
        roleId: role.id,
        permissionId: permission.id
      });
    } catch (e) {
      const error = e as ApiError;
      this.$bvToast.toast(
        `There was a problem toggling this permission - ${error.errorMessage}`,
        {
          title: 'Error toggling permission',
          toaster: 'b-toaster-bottom-center',
          solid: true,
          append: false
        }
      );
    }
  }

  public openAddRoleModal(): void {
    this.$bvModal.show('add-role-modal');
  }

  public async createRole(roleName: string): Promise<void> {
    try {
      const updatedRoles = await CreateRole({
        partnerId: this.currentOrgId(),
        roleName
      });
      this.roleManagement = {
        roles: updatedRoles,
        permissions: this.roleManagement?.permissions ?? []
      };
      this.$bvToast.toast('You can now add permissions to this role', {
        title: 'Role created',
        toaster: 'b-toaster-bottom-center',
        solid: true,
        append: false
      });

      GA.event<EventSettingsRoleAdd>(
        this.$gtag,
        new EventSettingsRoleAdd(roleName)
      );
    } catch (e) {
      const error = e as ApiError;
      this.$bvToast.toast(error.errorMessage, {
        title: 'Error creating role',
        toaster: 'b-toaster-bottom-center',
        solid: true,
        append: false
      });
    } finally {
      this.$bvModal.hide('add-role-modal');
    }
  }

  public async loadRoleManagementDetails(): Promise<void> {
    this.loading = true;
    const appName = this.getAppName(false);
    try {
      this.roleManagement = await GetRoleManagementDetails({
        partnerId: this.currentOrgId(),
        productType: appName
      });
    } catch (e) {
      this.errorMessage =
        'There was a problem loading this page, please try again';
    } finally {
      this.loading = false;
    }
  }

  public currentOrgId(): number {
    return User._token?.orgs[User._orgIndex].orgId ?? 0;
  }

  private async fetchUsers(): Promise<void> {
    this.users = await ProductStore.fetchUsers({
      partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0
    });
  }
}
