import { GAEvent } from '@/services/ga/events/GAEvent';

export class EventSettingsUserUpdate extends GAEvent {
  public eventName = 'settings-user-update';
  public userId?: number;
  public role?: string;

  constructor(userId?: number, role?: string) {
    super();
    this.userId = userId;
    this.role = role;
  }
}
