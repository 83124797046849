
import AppName from '@/components/mixin/AppName.vue';
import ConstantsMixin from '@/components/mixin/Constants.vue';
import AlertsSettings from '@/components/settings/AlertsSettings.vue';
import AlertsSettingsTable from '@/components/settings/AlertsSettingsTable.vue';
import RoleManagement from '@/components/settings/RoleManagement.vue';
import CustomFields from '@/components/settings/CustomFields.vue';
import Defaults from '@/components/settings/Defaults.vue';
import UserManagement from '@/components/settings/UserManagement.vue';
import { trackEvent } from '@/services/Mixpanel';
import ProductStore from '@/store/modules/ProductStore';
import { getListItem } from '@/utils/LocalStorageUtils';
import { isAdmin } from '@/utils/TestingValidity';
import { mixins } from 'vue-class-component';
import { Component, Inject, Watch } from 'vue-property-decorator';
import { PermissionService } from '@/services/PermissionService';

@Component({
  components: {
    UserManagement,
    CustomFields,
    AlertsSettings,
    AlertsSettingsTable,
    RoleManagement,
    Defaults
  },
  methods: {
    isAdmin
  }
})
export default class MilkSitesView extends mixins(AppName, ConstantsMixin) {
  public tab = 'user-management';
  public width = 1000;

  @Inject('permissionService')
  private permissionService!: PermissionService;

  mounted() {
    this.tab = this.$route.meta?.tab;
    trackEvent(`User viewing ${this.tab} on settings page`);
    if (
      !ProductStore.currentRoute.fuel ||
      ProductStore.currentRoute.fuel.length == 0
    ) {
      const checked = getListItem('fuelRoute');
      ProductStore.updateRouteState({ fuel: checked });
    }
    if (
      !ProductStore.currentAllProducts ||
      ProductStore.currentAllProducts.length == 0
    ) {
      ProductStore.fetchAllEntityStates('fuel');
    }
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
  }

  destroyed() {
    window.removeEventListener('resize', this.updateWidth);
  }

  public get canManageCustomFields(): boolean {
    return this.permissionService.canManageCustomFields;
  }

  public get canManageUsers(): boolean {
    return this.permissionService.canManageUsers;
  }

  public get canManageRoles(): boolean {
    return this.permissionService.canManageRoles;
  }

  public get canManageDefaultAlerts(): boolean {
    return this.permissionService.canManageDefaultAlerts;
  }

  public get canEditGeneralSettings(): boolean {
    return this.permissionService.canEditGeneralSettings;
  }

  public updateWidth() {
    this.width = window.innerWidth;
  }

  public switchTabs(tab: string) {
    trackEvent('User selected to switch tab on settings page', { tab: tab });
    if (this.tab != tab) {
      const path = this.$route.path.split('/');
      this.$router.push(`/${path[1]}/${path[2]}/${tab}`).catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error;
        }
      });
      this.tab = tab;
    }
  }
}
