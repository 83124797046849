
import DraggablePanel from '@/components/buttons/DraggablePanel.vue';
import FormTextInput from '@/components/common/FormTextInput.vue';
import ComplianceSettingsModal from '@/components/modal/ComplianceSettingsModal.vue';
import { storeState } from '@/store/Globals';
import CustomStore from '@/store/modules/CustomStore';
import { toTitleCase } from '@/utils/FormattingUtils';
import { getCountry } from '@/utils/LocationUtils';
import { isAdmin } from '@/utils/TestingValidity';
import cloneDeep from 'lodash.clonedeep';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import DesktopHeader from '../common/DesktopHeader.vue';
import DisplayInfoItem from '../common/DisplayInfoItem.vue';
import MobileHeader from '../common/MobileHeader.vue';
import ScrollableContainer from '../common/ScrollableContainer.vue';
import SidebarCard from '../common/SidebarCard.vue';
import AppName from '../mixin/AppName.vue';
import ConfirmationModal from '../modal/ConfirmationModal.vue';
import EditPreferredDefaultsModal from '../modal/EditPreferredDefaultsModal.vue';
import ThresholdSettingsDisplay from './ThresholdSettingsDisplay.vue';

@Component({
  components: {
    ScrollableContainer,
    DesktopHeader,
    DraggablePanel,
    ComplianceSettingsModal,
    FormTextInput,
    ConfirmationModal,
    MobileHeader,
    ThresholdSettingsDisplay,
    SidebarCard,
    DisplayInfoItem,
    EditPreferredDefaultsModal
  },
  methods: {
    isAdmin,
    getCountry,
    toTitleCase
  },
  computed: {
    ...mapGetters('user', {
      token: 'token'
    })
  }
})
export default class Defaults extends mixins(AppName) {
  @Prop() isTable!: boolean;
  @Prop() width!: number;
  public orgCustomSettings = null;

  get storeLoadingState() {
    return storeState.loading;
  }

  @Watch('storeLoadingState')
  storeStateUpdated() {
    if (!this.storeLoadingState) {
      this.loadCustomSettings();
    }
  }

  mounted() {
    if (!this.storeLoadingState) {
      this.loadCustomSettings();
    }
  }

  public loadCustomSettings() {
    if (CustomStore.orgCustomSettings) {
      this.orgCustomSettings = cloneDeep(CustomStore.orgCustomSettings)[
        this.getAppName(false)
      ][this.getEntityApiCamelCaseWord()];
    }
  }

  public openModal() {
    this.$root.$emit('bv::show::modal', 'editDefaultsModal', '#btnHide');
  }
}
