var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BContainer',{staticClass:"h-100 p-4 pt-4 bg-white containerOuter",attrs:{"id":"mainContent","fluid":""}},[_c('div',{staticStyle:{"max-width":"1000px"}},[_c('h3',{staticClass:"py-2 mb-0 text-left"},[_vm._v("Alerts (default values)")]),_c('p',{staticClass:"text-left pb-3"},[_vm._v(" When a "+_vm._s(_vm.getEntityName(false))+" has not been customised, the "+_vm._s(_vm.getEntityName(false))+" level notifications and alerts will be set to the following default values. These levels can be modified by an admin user. ")]),(_vm.storeLoadingState)?_c('b-spinner',{staticClass:"mt-4 text-center",attrs:{"variant":_vm.getAppColour(true)}}):_vm._e(),_c('div',{style:(_vm.storeLoadingState ? 'opacity: 50%' : '')},[_c('BRow',{staticStyle:{"max-width":"250px"}},[_c('BCol',{staticClass:"col180"},[_c('BInputGroup',{staticClass:"flex-nowrap",attrs:{"append":"%"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',{staticClass:"tankLevelAppend"},[_vm._v("%")])]},proxy:true}])},[_c('FormTextInput',{attrs:{"inputAttrs":{
                id: 'label',
                label: 'Low level',
                labelClass: 'text-primary d-flex',
                type: 'text',
                variant: _vm.getAppColour(true),
                value: _vm.lowLevelDefault,
                disabled: true
              },"id":"label"},model:{value:(_vm.lowLevelDefault),callback:function ($$v) {_vm.lowLevelDefault=$$v},expression:"lowLevelDefault"}})],1)],1),_c('BCol',{staticClass:"my-auto px-0",staticStyle:{"padding-bottom":"14px"}},[(_vm.canManageDefaultAlerts)?_c('BButton',{staticClass:"my-auto w-100",attrs:{"variant":"primary","id":"low-level-modal-button"},on:{"click":_vm.openLowLevelModal}},[_vm._v(" Edit ")]):_vm._e()],1)],1),_c('BRow',{staticStyle:{"max-width":"250px"}},[_c('BCol',{staticClass:"col180"},[_c('BInputGroup',{staticClass:"flex-nowrap",scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',{staticClass:"tankLevelAppend"},[_vm._v("%")])]},proxy:true}])},[_c('FormTextInput',{attrs:{"inputAttrs":{
                id: 'label',
                label: 'Critical level',
                labelClass: 'text-primary d-flex',
                type: 'text',
                variant: _vm.getAppColour(true),
                value: _vm.criticalLevelDefault,
                disabled: true
              },"id":"label"},model:{value:(_vm.criticalLevelDefault),callback:function ($$v) {_vm.criticalLevelDefault=$$v},expression:"criticalLevelDefault"}})],1)],1),_c('BCol',{staticClass:"my-auto px-0",staticStyle:{"padding-bottom":"14px"}},[(_vm.canManageDefaultAlerts)?_c('BButton',{staticClass:"my-auto w-100",attrs:{"variant":"primary","id":"critical-level-modal-button"},on:{"click":_vm.openCriticalLevelModal}},[_vm._v(" Edit ")]):_vm._e()],1)],1)],1)],1),_c('EditDefaultLevelsModal',{attrs:{"isLowLevel":true,"value":_vm.lowLevelDefault},on:{"updated":_vm.updateDefaults}}),_c('EditDefaultLevelsModal',{attrs:{"isLowLevel":false,"value":_vm.criticalLevelDefault},on:{"updated":_vm.updateDefaults}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }