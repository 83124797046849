var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BModal',{ref:"modal",staticClass:"d-flex",attrs:{"id":`editDefault${_vm.isLowLevel ? 'Low' : 'Critical'}LevelModal`,"title":`Edit ${_vm.isLowLevel ? 'Low' : 'Critical'} Level Alert (default value)`,"centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"modal-title w-100 text-center",staticStyle:{"margin-right":"-44px"},attrs:{"id":`editDefault${
          _vm.isLowLevel ? 'Low' : 'Critical'
        }LevelModal___BV_modal_title_`}},[_vm._v(" "+_vm._s(`Edit ${_vm.isLowLevel ? 'Low' : 'Critical'} Level Alert (default value)`)+" ")]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.closeEditModal()}}},[_vm._v(" × ")])]},proxy:true}])},[_c('p',{staticClass:"pt-1"},[_vm._v(" When a "+_vm._s(_vm.getEntityName(false))+" level is not over-ridden with a custom amount, the "+_vm._s(_vm.getEntityName(false))+" "+_vm._s(_vm.isLowLevel ? 'low' : 'critical')+" level notifications and alerts will be set to the following value. ")]),_c('BAlert',{attrs:{"show":"","variant":"danger"}},[_c('BRow',[_c('BCol',{staticClass:"col40"},[_c('BIcon',{attrs:{"icon":"exclamation-triangle-fill"}})],1),_c('BCol',{staticClass:"p-0"},[_c('p',{staticClass:"m-0"},[_vm._v(" Changing this default value will apply to ALL "+_vm._s(_vm.getEntityName(false))+"s that do not have a custom alert value ")])])],1)],1),_c('BRow',{staticClass:"pt-2",staticStyle:{"width":"250px"}},[_c('BCol',{staticClass:"col150"},[_c('BInputGroup',{staticClass:"flex-nowrap",staticStyle:{"width":"150px"},attrs:{"append":"%"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',{staticClass:"tankLevelAppend"},[_vm._v("%")])]},proxy:true}])},[_c('FormTextInput',{attrs:{"inputAttrs":{
            id: 'label',
            label: _vm.isLowLevel ? 'Low Level' : 'Critical Level',
            labelClass: 'text-primary d-flex',
            type: 'text',
            variant: _vm.getAppColour(true),
            value: _vm.editableValue
          },"id":"label"},model:{value:(_vm.editableValue),callback:function ($$v) {_vm.editableValue=$$v},expression:"editableValue"}})],1)],1),_c('BCol',{staticClass:"my-auto"},[_c('div',{staticClass:"pl-2 my-auto"},[_c('BIconstack',[_c('BIcon',{attrs:{"stacked":"","icon":"square-fill","shift-v":"16","variant":"white"},on:{"click":function($event){return _vm.increase()}}}),_c('BIcon',{attrs:{"stacked":"","icon":"square-fill","shift-v":"-4","variant":"white"},on:{"click":function($event){return _vm.decrease()}}}),_c('BIcon',{attrs:{"stacked":"","icon":"chevron-up","shift-v":"16"},on:{"click":function($event){return _vm.increase()}}}),_c('BIcon',{attrs:{"stacked":"","icon":"chevron-down","shift-v":"-4"},on:{"click":function($event){return _vm.decrease()}}})],1)],1)])],1),_c('div',{staticClass:"d-flex justify-content-center"},[_c('BButton',{staticClass:"text-primary hover-white mt-4 mr-2 px-5",attrs:{"variant":"outline-primary"},on:{"click":_vm.closeEditModal}},[_vm._v("Cancel")]),_c('BButton',{staticClass:"text-white mt-4 ml-2 px-5",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }