
import Component, { mixins } from 'vue-class-component';
import AppName from '../mixin/AppName.vue';
import { Prop } from 'vue-property-decorator';

@Component
export default class SettingsHeader extends mixins(AppName) {

    @Prop() title!: string;
    @Prop() buttonProps?: { label: string, to?: string, onClick?: () => void, disabled: boolean };

}
