
import FormDropdownInput from '@/components/common/FormDropdownInput.vue';
import FormTextInput from '@/components/common/FormTextInput.vue';
import CustomFieldsCardList from '@/components/list/CustomFieldsCardList.vue';
import AppName from '@/components/mixin/AppName.vue';
import CustomField from '@/store/models/CustomField';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  components: {
    FormTextInput,
    FormDropdownInput,
    CustomFieldsCardList
  },
  computed: {
    ...mapGetters('user', {
      token: 'token'
    })
  }
})
export default class AddCustomFieldDefModal extends mixins(AppName) {
  @Prop() addFormField!: CustomField;
  @Prop() modalType!: string;
  public addFieldError = false;
  public orgId = 0;
  public listOption = '';

  public addListItem() {
    if (this.addFormField.listOptions) {
      this.addFormField.listOptions.push(this.listOption);
      this.addFormField.listOptions.sort((a, b) => {
        if (a != null && b != null) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        } else if (a != null && b == null) {
          return -1;
        } else {
          return -1;
        }
      });
    } else {
      this.addFormField.listOptions = [this.listOption];
    }
    this.listOption = '';
  }

  public changeSortable() {
    this.addFormField.sortable = !this.addFormField.sortable;
  }

  public changeFilterable() {
    this.addFormField.filterable = !this.addFormField.filterable;
  }

  public submitAddNew() {
    if (
      this.addFormField.label == '' ||
      this.addFormField.type == '' ||
      (this.addFormField.type == 'List Entry' &&
        !this.addFormField.listOptions) ||
      (this.addFormField.type == 'List Entry' &&
        this.addFormField.listOptions &&
        this.addFormField.listOptions.length == 0)
    ) {
      this.addFieldError = true;
    } else {
      this.addFormField.name = this.addFormField.label;
      this.$emit('addNew');
    }
  }

  public removeItem(item: string) {
    if (this.addFormField.listOptions) {
      this.addFormField.listOptions = this.addFormField.listOptions.filter(
        option => !(option == item)
      );
    }
  }

  public closeAddModal() {
    this.addFieldError = false;
    this.$root.$emit(
      'bv::hide::modal',
      `add${this.modalType}CustomField`,
      '#btnHide'
    );
  }
}
